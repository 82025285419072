import React, { useState, useEffect } from "react";
import * as yup from 'yup';
import moment from 'moment';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import SEO from "../components/seo";
import Layout from '../components/layout';
import Alert from '../components/alerts/alert';
import Form from '../components/form/form';
import { apiUrl, postRequest } from '../utils/request';

const price = 30;

const quantityOptions = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
];

const giftForm = {
  order: [
    'first_name',
    'last_name',
    'email',
    'phone',
    'quantity',
    'pay',
    'submit',
  ],
  fields: {
    first_name: {
      schema: yup.string().max(255).required().trim(),
      label: "Your First Name",
      type: "text",
    },
    last_name: {
      schema: yup.string().max(255).required().trim(),
      label: "Your Last Name",
      type: "text",
    },
    email: {
      schema: yup.string().email().required().max(255).trim(),
      label: "Your Email",
      type: "email",
      // placeholder: "e.g. steve@aol.com",
      helptext: "Your gift codes will be sent to this email address.",
    },
    phone: {
      schema: yup.string().required().trim(),
      customValidation: (field, value, values) => {
        const msg = "You must provide a valid US mobile phone number.";
        if (!value) {
          return msg;
        }
        const phoneNumber = parsePhoneNumberFromString(value, 'US');
        return !phoneNumber || !phoneNumber.isValid() ? msg : null;
      },
      label: "Your Phone #",
      type: "phone",
      // placeholder: "e.g. steve@aol.com",
      // helptext: "Confirm your password to prevent typos.",
    },
    quantity: {
      schema: yup.number().max(10).required(),
      label: "Number Of Gift Codes To Purchase",
      type: "select",
      options: quantityOptions,
    },
    pay: {
      type: 'custom',
      name: 'pay',
      render: () => {
        return <CardElement className="mt-4 mb-8 bg-white py-3 pl-2 border-gray-300 border rounded-lg shadow" />;
      }
    },
    submit: {
      type: 'button',
      submit: true,
      text: 'Purchase',
      color: "blue",
      block: true,
    },
  },
};

function GiftOrderForm({ handleSuccess, handleError, onUpdate, defaults }) {
  const stripe = useStripe();
  const elements = useElements();
  const [submitting, setSubmitting] = useState(false);


  const handleSubmit = async (values, setErrors) => {
    setSubmitting(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      setSubmitting(false);
      return;
    }

    // Check to be sure that we have the required data

    // Get a reference to a mounted CardElement.
    const cardElement = elements.getElement(CardElement);

    const { email, first_name: firstName, last_name: lastName } = values;
    const name = `${firstName} ${lastName}`;

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: { email, name },
    });

    if (error) {
      // Here we call the handleError function, with the error
      handleError(error);
      setErrors({});
      setSubmitting(false);
      // console.log('[error]', error);
    } else {
      await handleSuccess({ ...values, paymentMethod });
      setErrors({});
      setSubmitting(false);
      // Here is where we call the handleSuccess function, passing along the payment method
      // console.log('[PaymentMethod]', paymentMethod);
    }
  };

  return (
    <Form defaults={defaults} onUpdate={onUpdate} submitForm={handleSubmit} form={giftForm} />
  );
}





const GiftOrder = () => {
  const [alert, setAlert] = useState(null);
  const [expires, setExpires] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [complete, setComplete] = useState(false);


  useEffect(() => {
    setExpires(moment().add(6, 'months').format('M/D/YYYY'));
  }, []);

  const lookForQuantityUpdate = (values) => {
    const { quantity: q } = values;
    if (q && q !== quantity) {
      setQuantity(q);
    }
  }

  const handleError = (error) => {
    // console.log(error, 'this is the error');
    setAlert({
      type: 'error',
      body: error.message,
    });
  };


  const handleSuccess = async (data) => {
    setAlert(null);

    const { paymentMethod } = data;

    // Basic validation 
    if (!paymentMethod || !paymentMethod.id) {
      setAlert({
        type: 'error',
        body: "Something went wrong processing your payment, please try again.",
      });
      return;
    }

    const genericError = "An unknown error has occurred, you have not been charged.  Please try again.";
    // POST TO THE SERVER!!!
    const url = `${apiUrl}/purchase/trialcodes/process`;
    console.log(data, 'the data');
    try {
      const result = await postRequest(url, data);
      // console.log(result, 'RESULT');
      if (result && result.status === 'success') {
        // console.log(result, 'the result');
        // navigate(`/app/dashboard?new=1`);
        setAlert({
          type: 'success',
          body: 'Thanks for your purchase!  Please check your email for instructions!',
        });
        window.scrollTo(0, 0);
        setComplete(true);
      } else {
        const msg = result && result.message ? result.message : genericError;
        setAlert({
          type: 'error',
          body: msg,
        });
      }
    } catch (err) {
      let message = genericError;
      if (err && err.response && err.response.data && err.response.data.message) {
        message = err.response.data.message;
      }
      setAlert({
        type: 'error',
        body: message,
      });
      window.scrollTo(0, 0);
    }

  };


  const paragraphClasses = 'text-lg md:text-xl text-gray-700 leading-8 mb-8';
  const containerClasses = 'container px-2 mx-auto flex flex-wrap items-center';
  const widthClasses = 'lg:w-1/2 md:w-2/3 flex flex-col md:mx-auto w-full';
  const headlineCLasses = 'text-center text-3xl md:text-4xl leading-9 font-bold text-gray-900 mb-8 font-headline';
  // const subHeadlingClasses = 'text-center text-2xl md:text-2xl leading-9 font-bold text-gray-900 mb-8 font-headline';

  return (
    <Layout>
      <SEO title="Gift Order" />
      <section className="bg-white pt-6 px-4 pb-4 md:px-0">
        <div className={containerClasses}>
          <div className={widthClasses}>
            <h2 className={headlineCLasses}>Gift the Sidewall Club Membership</h2>
            <p className={paragraphClasses}>
              This is a 6 month membership and expires {expires}.  Upon purchase you'll receive a redemption code that you'll share with the recipient.
              This code will automatically grant the recipient access until {expires}.
            </p>
          </div>
        </div>
      </section>
      <section className="bg-white">
        <div className="container px-5 pb-24 mx-auto flex flex-wrap items-center">
          <div className="xl:w-2/6 lg:w-1/2 md:w-1/2 flex flex-col md:mx-auto w-full">
            {alert && <Alert {...alert} />}
            {!complete && (
              <div>
                <div className="rounded overflow-hidden shadow-lg border border-gray-100">
                  <div className="m-4">
                    <h2 className="text-2xl font-semibold text-gray-700 mb-3 font-headline">This is temporarily disabled.</h2>
                    {/* <p className="text-2xl text-gray-800 mb-4">Purchasing {quantity} at ${price} for a total of ${price * quantity}.</p> */}

                    {/* <h2 className="text-2xl font-semibold text-gray-700 mb-3 font-headline">Gift the Sidewall Club!</h2>
                    <p className="text-lg text-gray-700 mb-4">Give the gift of the Sidewall Club!!!</p> */}
                    {/* <GiftOrderForm defaults={{ quantity }} handleSuccess={handleSuccess} handleError={handleError} onUpdate={lookForQuantityUpdate} /> */}
                    {/* <p className="text-xl text-gray-800 mb-4">Purchasing {quantity} at ${price} for a total of ${price * quantity}.</p> */}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default GiftOrder;
